main {
  min-height: 80vh;
}

/* Header Styles */
header {
  background-color: #ffffff;
  padding: 16px 32px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.headerlogo {
  display: flex;
  align-items: center;
  font-family: 'Playfair Display', serif;
}

.headerlogo img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-right: 10px;
}

.brand-text {
  color: #1c5d38;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-link {
  color: #1c5d38 !important;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #c8e6c9 !important;
}

.nav-link svg {
  margin-right: 5px;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background-color: #f8f9fa;
}

/* AutocompleteInput styles */
.autocomplete {
  position: relative;
  width: 100%;
}

/* Search Input and Button Styles */
.autocomplete-search-form {
  display: flex;
  width: 100%;
  align-items: stretch; /* Make sure items stretch to fill the form */
}


.autocomplete-search-input {
  flex-grow: 1; /* Allow input to fill available space */
  padding: 0.75rem 1rem; /* Adjust padding as needed */
  font-size: 1rem;
  border: 2px solid #007bff; /* Border color to match button */
  border-top-left-radius: 0.75rem; /* Rounded corners for input field */
  border-bottom-left-radius: 0.75rem;
  border-right: none; /* Remove right border where button meets input */
  box-shadow: none; /* Optional: remove if you don't want any shadow */
}

.autocomplete-search-button {
  padding: 0.75rem 1rem; /* Same padding as the input for equal height */
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border-top-right-radius: 0.75rem; /* Rounded corners for button */
  border-bottom-right-radius: 0.75rem;
  border: 2px solid #007bff;
  border-left: none; /* Remove left border to blend with input */
  transition: background-color 0.3s ease;
  box-shadow: none; /* Optional: remove if you don't want any shadow */
}

/* Ensure that the button aligns perfectly with the input */
.autocomplete-search-button:hover,
.autocomplete-search-button:focus {
  background-color: #0056b3; /* Darken button on hover/focus for interactivity */
}

/* Adjusting the input and button size for mobile screens */
@media (max-width: 767px) {
  .autocomplete-search-input {
    padding: 0.75rem 1rem; /* Maintain larger input on mobile */
  }

  .autocomplete-search-button {
    padding: 0.375rem 0.75rem; /* Maintain smaller button on mobile */
  }
}

.autocomplete-dropdown {
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.autocomplete-suggestion-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.autocomplete-suggestion-item:hover {
  background-color: #f8f9fa;
}

.autocomplete-suggestion-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 0.25rem;
}

.autocomplete-suggestion-info {
  flex: 1;
}

.autocomplete-suggestion-name {
  font-size: 1rem;
  font-weight: bold;
}

.autocomplete-suggestion-price {
  font-size: 0.875rem;
  color: #000000;
}

.autocomplete-view-all-results {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.autocomplete-view-all-results:hover {
  background-color: #f8f9fa;
}

.autocomplete-no-results {
  padding: 0.5rem;
  text-align: center; 
  color: #6c757d;
}

.price-container {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.price-label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

.price-value {
  font-size: 2rem;
  font-weight: bold;
  color: #dc3545;
}

/* Responsive styles */
@media (max-width: 767px) {
  .product-img {
    height: 150px;
  }

  .autocomplete-suggestion-image {
    width: 40px;
    height: 40px;
  }

  .autocomplete-suggestion-name {
    font-size: 0.9rem;
  }

  .autocomplete-suggestion-price {
    font-size: 0.8rem;
  }

  .price-label {
    font-size: 1rem;
  }

  .price-value {
    font-size: 1.5rem;
  }
}